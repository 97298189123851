<!-- 规划导览 -->
<template>
    <div>
        <div class="page_temp page_temp_left">
            <div class="contentBox">
                <item-title
                    name="辖区概述"
                    english="Jurisdiction Overview"
                />
                <lineBorderBox type='bottom' />
                <div style="padding: .1rem .15rem;">
                    <div class="img">
                        <lineBorderBox />
                        <img :src="countyInfo?countyInfo.image_add:''">
                    </div>
                    <div>
                        <div class="info_title">
                            <div class="info_dot"><span></span></div>
                            <span>区域简介</span>
                        </div>
                    </div>
                    <div class="cls-button-name">
                        {{ countyInfo.abstracts }}
                    </div>
                </div>
            </div>
            <div
                class="contentBox"
                style="padding-bottom: 0.2rem;"
            >
                <item-title
                    style="margin-top: 0.2rem;"
                    name="辖区数据统计"
                    english="Jurisdiction Overview"
                />
                <lineBorderBox type='bottom' />
                <div>
                    <div class="dataList-item">
                        <img
                            src="../assets/new_img/a1.png"
                            alt=""
                        >
                        <div class="item-info">
                            <span class="type">辖区面积</span>
                            <div>
                                <span>3325</span>
                                <span>（平方公里）</span>
                            </div>
                        </div>
                    </div>
                    <div class="dataList-item">
                        <img
                            src="../assets/new_img/a2.png"
                            alt=""
                        >
                        <div class="item-info">
                            <span class="type">人口总数</span>
                            <div>
                                <span>26.3</span>
                                <span>（万人）</span>
                            </div>
                        </div>
                    </div>
                    <div class="dataList-item">
                        <img
                            src="../assets/new_img/a3.png"
                            alt=""
                        >
                        <div class="item-info">
                            <span class="type">乡镇</span>
                            <div>
                                <span>11</span>
                                <span>（个）</span>
                            </div>
                        </div>
                    </div>
                    <div class="dataList-item">
                        <img
                            src="../assets/new_img/a4.png"
                            alt=""
                        >
                        <div class="item-info">
                            <span class="type">乡村</span>
                            <div>
                                <span>257</span>
                                <span>（个）</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="page_temp page_temp_right">
            <div
                class="contentBox"
                style="padding-bottom: 0.2rem;"
            >
                <item-title
                    name="辖区特产资源"
                    english="Source Han Sans CN-Medi"
                />
                <lineBorderBox type='bottom' />
                <div style="padding: 0 0.08rem;">
                    <img-text-list
                        style="margin-top: 0.1rem;box-sizing: border-box;"
                        height="8.3rem"
                        :dataList="resourcesData"
                    ></img-text-list>
                </div>
            </div>
        </div>
        <!-- 乡镇简要信息弹窗 -->
        <dot-pop
            :name="tabOption.name"
            :x="tabOption.x"
            :y="tabOption.y"
            :z="tabOption.z"
            :width="400"
            :height="250"
            @closePane="clearPop"
            v-if="messageActive == 4"
        >
            <div
                class="cls-pane-content"
                style="font-size: .12rem;color: #fff;line-height: .38rem;margin-top: .2rem;"
            >
                {{ tabOption.introduce }}</div>
        </dot-pop>
        <!-- 乡村点位信息 -->
        <div
            v-for="(item, index) in villageFeatures"
            :key="index"
            @click="toVillagePage(item.fieldValues[1])"
            class="dotPop"
        >
            <dot-pop
                page="county"
                :name="item.fieldValues[2]"
                :x="Number(item.fieldValues[3])"
                :y="Number(item.fieldValues[4])"
                :color="dotColor"
                :z="Number(item.fieldValues[0])"
                type='dot-county'
            >
                <img
                    class="dot_icon"
                    src="../assets/new_img/county_icon2.png"
                >
            </dot-pop>
        </div>

    </div>

</template>

<script>
import http from '../util/http.js';
import { getLonLatArray, addPoint, addLine, addPolygonFunction, cleanMarkerListByType, getMakerById } from '../util/drawShape.js';
import messagePop from '../components/message-pop.vue'
import { getBasic, getIconList, getOverView } from '@/api/tutorials.js'
import dotPop from "@/components/dot-pop.vue";
import config from '@/config.js'
import itemTitle from '../components/item-title.vue'
import lineBorderBox from '../components/line-border-box.vue'
import imgTextList from "@/components/imgTextList.vue"

var g_is_active_border = false;
var g_border_points = [];     //辖区边界坐标
var g_entity_polygon = null;  //辖区边界线

var g_handler = null;
var g_xian_code = config.TENANT_CODE.substr(0, 6) + '000000';   //'421224000000';
var g_xq_code = config.TENANT_CODE.substr(0, 6) + '000';   //'421224000';
var g_xqid = config.TENANT_CODE //'420626107204';

export default {
    components: { messagePop, dotPop, itemTitle, lineBorderBox, imgTextList },
    data() {
        return {
            // 乡镇简要信息弹窗配置
            tabOption: {
                width: 200,
                height: 100,
                name: '乡镇',
                introduce: '',
                x: 0.0,
                y: 0.0,
                z: 0.0
            },
            // 弹窗编号
            messageActive: 0,
            v_boolean_show: true,
            v_if_show_vr: false,
            v_list_vr: [],
            villageList: [],
            path: '/tutorials',//即将要跳转的子页面
            countyInfo: {},
            img_area: require('@/assets/new_img/m1.png'),
            img_count: require('@/assets/new_img/m4.png'),
            img_county: require('@/assets/new_img/m3.png'),
            img_village: require('@/assets/new_img/m2.png'),
            villageFeatures: [],
            dotColor: '#0084ff',
            // 特色资源数据
            resourcesData: [],
        }
    },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.initData()
                let countyData = JSON.parse(sessionStorage.getItem('token'))
                if (countyData.tenant) {
                    this.villageList = countyData.tenant.child[0].child
                }
            }
        }, 500)
    },
    methods: {
        // 初始化数据
        initData: function () {
            this.initMap()

            this.requestXian();
            this.requestTownList();
            this.requestAllCun();
            this.getCountyInfo();

            // this.$parent.show2DMap();
			
			this.getIconList()

        },
        // 获取县城概况信息
        getCountyInfo() {
            getOverView().then(res => {
                this.countyInfo = res.data
            })
        },
        // 获取辖区特产资源
        getIconList: function () {
            let data = {
                pageNum: 1,
                type: 3,
                pageSize: 14,
                county: true
            }
            getIconList(data).then(res => {
                this.resourcesData = res.rows
            })
        },
        initMap: function () {
            getBasic().then(res => {
                //边界坐标字符串
                g_border_points = res.data.initialFeature.geometry.pointArr;

            })
        },

        //显示与隐藏辖区边界线
        toggleXqBorder: function () {
            if (g_is_active_border) {
                if (g_entity_polygon) {
                    viewer.entities.remove(g_entity_polygon);
                    g_entity_polygon = null;
                    console.log('--隐藏辖区边线');
                }
            } else {
                if (g_border_points.length > 0) {//如果已经缓存坐标串，直接画面，不需请求服务器
                    g_entity_polygon = this.drawXqBorderPolygon(g_border_points);
                    // viewer.flyTo(g_entity_polygon, {
                    //     duration: 5,
                    //     offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                    // });
                    console.log('--显示辖区边线', g_border_points.length);
                }
            }
            g_is_active_border = !g_is_active_border;
        },

        //画边界线
        drawXqBorderPolygon: function (pointArr) {
            return viewer.entities.add({
                polyline: {
                    clampToGround: true,
                    positions: Cesium.Cartesian3.fromDegreesArray(pointArr),
                    material: new Cesium.PolylineGlowMaterialProperty({
                        glowPower: 0.5,
                        color: Cesium.Color.ORANGERED,
                    }),//Cesium.Color.RED, //new Cesium.Color.fromCssColorString("#FF0000"), //.withAlpha(.2),                     
                    width: 4,
                    clampToGround: true
                }
            });
        },

        // 关闭弹窗
        clearPop: function () {
            this.messageActive = 0
        },

        requestXian() {
            var _str_filter = "code= '" + g_xian_code + "' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420000:xzqh_xian"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["smid", "code", "name", "center"]
                },
                "maxFeatures": 100
            }
            http.requestIServer(_sqlParameter, (result) => {
                console.log('--县回调--', result.totalCount);
                if (result.features.length == 0) {
                    return;
                }
                var minLat = 90;
                var minLon = 180;
                var maxLat = 0;
                var maxLon = 0;
                var _points = result.features[0].geometry.points;
                for (var i = 0; i < _points.length; i++) {
                    var _point = _points[i];
                    var lat = _point.y;
                    var lon = _point.x;
                    minLat = Math.min(minLat, lat);
                    minLon = Math.min(minLon, lon);
                    maxLat = Math.max(maxLat, lat);
                    maxLon = Math.max(maxLon, lon);
                }
                console.log('--县坐标范围--', minLon, minLat, maxLon, maxLat);
                var _config = { width: 5 };
                viewer.camera.flyTo({
                    destination: Cesium.Rectangle.fromDegrees(minLon, minLat, maxLon, maxLat) //west, south, east, north)
                });
            });
        },

        requestTownList: function () {
            cleanMarkerListByType('county');
            var _str_filter = "countycode= '" + g_xq_code + "' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420000:xzqh_zhen"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["smid", "code", "name", "introduce"],
                    "orderby": 'code'
                },
                "maxFeatures": 100
            }
            http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
                console.log('--乡镇列表回调--', result.totalCount);
                if (result.features.length > 0) {
                    var colorArr = ['#2977ff', '#73c3fe', '#6fecff', '#dafae2', '#9ff4dd', '#32c599', '#fdee21', '#facb59', '#ffcc99', '#dc8015', '#f18990', '#808000'];
                    //var colorArr = ['#ffa500', '#808000', '#ff7f50', '#8a2be2', '#66cccc', '#ccff66', '#ffcc99', '#cc9999', '#ff6666', '#990033', '#cccc99'];
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        var _lonLatArr = getLonLatArray(_feature.geometry.points);
                        var _item = {
                            id: i,
                            code: _feature.fieldValues[1],
                            name: _feature.fieldValues[2],
                            introduce: _feature.fieldValues[3],
                            shape: _lonLatArr
                        };
                        var config = { polygonFillColor: colorArr[i % colorArr.length], alpha: 0.6 };  //, classificationType: 'S3M_TILE'
                        addPolygonFunction('county', i, _item.name, _item.shape, _item.introduce, config);
                    }
                    this.addClickEvent();
                }
            });
        },

        addClickEvent: function () {
            //单击选中事件
            g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            g_handler.setInputAction((movement) => {
                var position = viewer.scene.pickPosition(movement.position);
                var cartographic = Cesium.Cartographic.fromCartesian(position);
                var longitude = Cesium.Math.toDegrees(cartographic.longitude);
                var latitude = Cesium.Math.toDegrees(cartographic.latitude);
                var height = cartographic.height;
                viewer.entities.removeById('focus-line');
                this.messageActive = 0;
                var pick = viewer.scene.pick(movement.position);
                if (Cesium.defined(pick)) {
                    if (pick.id) {
                        //选中
                        var _entity = pick.id;
                        console.log('单击选中', _entity);
                        if (_entity.ptype == 'county') {
                            this.tabOption.name = _entity.data.mc;
                            this.tabOption.introduce = _entity.data.popHtml;
                            this.tabOption.x = longitude;
                            this.tabOption.y = latitude;
                            this.tabOption.z = 20;//height;
                            this.messageActive = 4;

                            viewer.entities.add({
                                id: 'focus-line',
                                polyline: {
                                    clampToGround: true,
                                    positions: _entity.polygon.hierarchy.getValue().positions, //Cesium.Cartesian3.fromDegreesArray(pointArr),
                                    //material: Cesium.Color.RED, //new Cesium.Color.fromCssColorString("#FF0000"), //.withAlpha(.2),                     
                                    material: new Cesium.PolylineGlowMaterialProperty({
                                        glowPower: 0.5,
                                        color: Cesium.Color.RED, //ORANGERED,
                                    }),
                                    width: 8
                                }
                            });
                        } else if (_entity.ptype == 'cwh') {
                            this.toVillagePage(_entity.data.id);
                        }
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        },

        //
        requestAllCun() {
            var _str_filter = "code like '" + g_xian_code.substring(0, 6) + "%' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420000:xzqh_cun"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["smid", "code", "name", "cwx", "cwy"],
                    "orderby": 'code'
                },
                "maxFeatures": 1000
            }
            http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
                console.log('--村列表回调--', result.totalCount);

                for (var _feature of result.features) {
                    var _config = { z: this.$parent.getZ(), width: 20, height: 25, distanceDisplayCondition: 500000 };
                    var _shape = [_feature.fieldValues[3], _feature.fieldValues[4]]
                    // addPoint('cwh', _feature.fieldValues[1], null, _shape, '', _config);
                }
                this.villageFeatures = result.features.reverse()
            });
        },
        toVillagePage(code) {
            this.villageList.forEach(item => {
                if (code == item.code) {
                    localStorage.setItem('tenantName', item.name)
                    localStorage.setItem('tenantCode', item.code)
                    localStorage.setItem('tenantId', item.id);
                    //this.$parent.requestMapConfig();//更新父组件的地图配置
                    this.$router.push({ path: this.path });
                    return;
                }
            })
            this.$router.push({ path: this.path });
        }
    },

    destroyed() {
        cleanMarkerListByType('county');
        cleanMarkerListByType('xian');
        cleanMarkerListByType('cwh');
        viewer.entities.remove(g_entity_polygon);
        viewer.entities.removeById('focus-line');
        g_entity_polygon = null;

        if (g_handler) {
            g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }

        g_handler = g_handler && g_handler.destroy();
    }
}
</script>
<style>
.cls-pane-content {
    font-size: 12px;
    color: #bbb;
}
</style>
<style lang="scss" scoped>
.contentBox {
    box-sizing: border-box;
    .img {
        position: relative;
    }
}

.cls-button-name {
    line-height: 0.24rem;
    box-sizing: border-box;
    overflow: auto;
    font-size: 0.14rem;
    text-indent: 2em;
}

.cls-button-name::-webkit-scrollbar {
    width: 0.04rem;
}

.cls-button-name::-webkit-scrollbar-thumb {
    background-color: #ddd;
    width: 0.04rem;
    border-radius: 0.05rem;
}

.img {
    width: 100%;
    overflow: hidden;
    height: 2rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 96%;
        height: 96%;
    }
}

.img-item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(225, 225, 225, 0.3);
    height: 0.68rem;
    border-radius: 0.04rem;
    margin-top: 0.15rem;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 0.24rem;

    img {
        margin-right: 0.16rem;
        width: 0.38rem;
        height: 0.38rem;
        border-radius: 50%;
    }

    div {
        font-size: 0.14rem;

        span {
            font-size: 0.16rem;
            margin: 0 0.16rem;
            color: #fff000;
        }
    }
}

.img-item-first {
    margin-top: 0.25rem;
}

.img-box {
    display: flex;
    justify-content: space-between;

    .img-item {
        width: 48%;

        div {
            display: flex;
            flex-direction: column;

            div {
                display: flex;
                flex-direction: row;

                span {
                    margin-left: 0;
                }
            }
        }
    }
}
.dot_icon {
    width: 110px;
    transform: translate(60px, -46px);
}
.module-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 0.2rem;
    .module-item {
        width: 1.26rem;
        height: 1.26rem;
        background-image: url(../assets/img/server/a2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .name {
            margin: 0.06rem 0;
        }
        .title {
            font-size: 0.14rem;
            color: #ffc748;
            span {
                font-size: 0.24rem;
            }
        }
    }
    .module-item:hover {
        background-image: url(../assets/img/server/a1.png);
        .title {
            color: #fff;
            span {
                color: #22f2ff;
            }
        }
    }
}
.info_title {
    display: flex;
    align-items: center;
    .info_dot {
        width: 0.12rem;
        height: 0.12rem;
        margin: 0.1rem 0;
        border: 0.01rem solid #069999;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        span {
            display: block;
            width: 0.06rem;
            height: 0.06rem;
            box-shadow: 0 0 0.03rem 0.01rem #18f5f5;
            background-color: #18f5f5;
        }
    }
}
.info_title > span {
    font-size: 0.14rem;
    font-weight: 500;
    color: #00ffff;
    line-height: 0.2rem;
    margin-left: 0.1rem;
}
.dataList-item {
    height: 0.63rem;
    background: rgba(3, 32, 77, 0.16);
    border: 0.01rem solid;
    margin-top: 0.1rem;
    border-image: linear-gradient(
            90deg,
            rgba(24, 142, 245, 1),
            rgba(24, 142, 245, 0)
        )
        1 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    img {
        margin: 0 0.08rem;
        width: 0.4rem;
        height: 0.4rem;
        box-sizing: border-box;
    }
    .item-info {
        display: flex;
        flex-direction: column;
        font-size: 0.14rem;
        width: 100%;
        .type {
            background: linear-gradient(
                90deg,
                rgba(25, 152, 255, 0.2) 0%,
                rgba(24, 142, 245, 0) 100%
            );
            font-size: 0.14rem;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #c4def5;
            line-height: 0.18rem;
            padding-left: 0.08rem;
        }
        div {
            display: flex;
            span:first-child {
                width: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.32rem;
                font-weight: bold;
                color: #19f5f5;
                line-height: 0.36rem;
                text-shadow: 0px 0px 0.08rem #3385ff;
            }
            span:last-child {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                font-size: 0.1rem;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.6);
                line-height: 0.12rem;
            }
        }
    }
}
</style>